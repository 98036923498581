import Cookies from 'js-cookie';

import trackedNavigate from './trackedNavigate';
import { getSession, handleLogin } from './auth';

export const LoginUserFields = `
  id
  email
  name
  avatarUrl
  avatarColor
  isSuperUser
  memberships {
    id
    role
    issuesFilter
    account {
      id
    }
  }
  agencyMembership {
    id
    role
    agency {
      id
      name
    }
  }
  settings {
    key
    name
    value
  }
  errors {
    attribute
    code
    sentence
    data {
      key
      value
      valueArray
    }
  }
`;

export const LoginFields = `
  accessToken
  refreshToken
  user {
    ${LoginUserFields}
  }
`;

const deconstructUser = (user) => {
  const userData = {
    userId: user?.id,
    userName: user?.name,
    userEmail: user?.email,
    avatarUrl: user?.avatarUrl,
    avatarColor: user?.avatarColor,
    userSettings: {},
    isSuperUser: user?.isSuperUser,
    agencyId: user?.agencyMembership?.agency?.id,
    agencyName: user?.agencyMembership?.agency?.name,
    agencyRole: user?.agencyMembership?.role,
    accountRoles: (user?.memberships || []).map(m => ({
      id: m.account.id,
      role: m.role,
      issuesFilter: m.issuesFilter,
    }))
  }

  if (user?.settings) {
    user.settings.forEach(s => userData.userSettings[s.key] = s.value);
  }

  return userData;
}

export function login({ accessToken, refreshToken, rememberMe, user, accountId, handshakeLogoutUrl }) {
  handleLogin({
    accessToken,
    refreshToken,
    rememberMe,
    accountId,
    handshakeLogoutUrl,
    ...deconstructUser(user),
  });

  trackedNavigate(getDesiredLocationAfterLogin());
}

export function refreshUser(user) {
  const { accessToken, refreshToken, rememberMe, accountId, handshakeLogoutUrl } = getSession();

  handleLogin({
    accessToken,
    refreshToken,
    rememberMe,
    accountId,
    handshakeLogoutUrl,
    ...deconstructUser(user),
  });
}

export function rememberDesiredLocationAfterLogin(location) {
  console.debug(`Remembering desired location: ${location}`);
  Cookies.set("login-return-to", location, { sameSite: "Strict" });
}

function getDesiredLocationAfterLogin() {
  const location = Cookies.get("login-return-to") || "/";
  Cookies.remove("login-return-to");
  console.debug(`Restoring desired location: ${location}`);
  return location;
}
