// samltest.dubbot.com and beta-ui-samltest.dubbot.com are custom domains
// beta-ui.dubbot.com and beta-ui-app.dubbot.com are not

const appDomain = new RegExp('^(app.)?dubbot.');
const betaDomain = new RegExp('.+.beta.dubbot.com');
const stagingDomain = new RegExp('.+.staging.dubbot.com');

function isCustomDomain(hostname) {
  return !appDomain.exec(hostname) &&
    !betaDomain.exec(hostname) &&
    !stagingDomain.exec(hostname) &&
    hostname !== 'dev.dubbot.com' &&
    hostname !== 'localhost' &&
    hostname !== 'staging-app.dubbot.com'
}

export function determineCustomDomain(hostname) {
  if (isCustomDomain(hostname)) {
    return hostname;
  }
}
