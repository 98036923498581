import React from 'react';

let coreUserSettings = null;

export const IntercomAPI = (...args) => {
  if (window.Intercom) {
    // Merge core user data with Intercom('update') props.
    if (args[0] === 'update') {
      args[1] = {...args[1], ...coreUserSettings};
    }

    window.Intercom.apply(null, args);
  }
};

function Intercom(props) {
  const { appId, currentAccount, currentUser } = props;

  React.useEffect(() => {
    if (!appId || !currentUser || !currentUser.email) {
      return;
    }

    const intercomScript = document.createElement('script');
    const userSettings = {
      app_id: appId,
      user_id: currentUser.id,
      name: currentUser.name,
      email: currentUser.email,
      created_at: currentUser.confirmedAt,
      sign_in_count: currentUser.signInCount,
      is_super_user: currentUser.isSuperUser
    };

    if (currentUser.isSuperUser) {
      userSettings['company'] = {
        id: 'dubbot',
        name: 'DubBot'
      };
    } else if (currentAccount && currentAccount.id) {
      userSettings['company'] = {
        id: currentAccount.id,
        name: currentAccount.name
      };
    }

    intercomScript.src = `https://widget.intercom.io/widget/${appId}`;
    intercomScript.async = true;
    intercomScript.onload = () => {
      if (coreUserSettings === null) {
        coreUserSettings = {
          app_id: appId,
          user_id: currentUser && currentUser.id,
          email: currentUser && currentUser.email,
        };
      }

      if (window.Intercom) {
        window.Intercom('boot', userSettings);
      }
    };

    document.head.appendChild(intercomScript);

    return () => {
      coreUserSettings = null;
      document.head.removeChild(intercomScript);
    }
  }, [appId, currentUser, currentAccount]);


  return null;
};

export default Intercom;